















import { defineComponent, computed } from '@vue/composition-api';
import merge from 'lodash/merge';
import { genChart } from '../utils';
import DataSchema from '../@types/data-schema';

export default defineComponent({
  props: {
    data: {
      required: true,
      type: Object as () => DataSchema,
    },
  },
  setup(props) {
    const {
      dataSet, dataLabels, id, title, chartConfigs,
    } = props.data;

    const chartData = { ...chartConfigs };

    merge(chartData, {
      data: {
        x: 'x',
        type: 'bubble',
      },
      axis: {
        x: {
          label: {
            position: 'outer-center',
          },
        },
        y: {
          label: {
            position: 'outer-middle',
          },
        },
      },
      bindto: `#${id}`,
    });

    return {
      id,
      title,
      chartData,
    };
  },
  mounted() {
    const chart = genChart(this.chartData);
  },
});
